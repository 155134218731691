import { Checkbox, FormGroup, FormControlLabel } from "@mui/material";
import styles from "./input.module.css";

const CheckBoxesInput = (props) => {
	const { items, question, field, setValue, hasError, errorMessage } = props;

	const selectedValues = Array.isArray(field.value) ? field.value : [];

	const handleChange = (e) => {
		const optionId = e.target.value;
		let newValue;

		if (selectedValues.includes(optionId)) {
			newValue = selectedValues.filter((id) => id !== optionId);
		} else {
			if (optionId === "40") {
				newValue = ["40"];
			} else {
				newValue = [...selectedValues, optionId];
			}
		}
		setValue(field.name, newValue, {
			shouldValidate: true,
			shouldTouch: true,
		});
	};

	return (
		<label className={styles.inner_form_label}>
			<p>
				{question} <span> (Select as many as possible)</span>
			</p>

			<FormGroup
				style={{ display: "block", width: "100%", marginTop: "24px" }}
			>
				{items.map((item) => {
					const isDisabled =
						selectedValues.includes("40") &&
						item.option_id.toString() !== "40";
					return (
						<FormControlLabel
							key={item.option_id}
							value={item.option_id.toString()}
							onChange={handleChange}
							control={
								<Checkbox
									value={item.option_id.toString()}
									checked={selectedValues.includes(
										item.option_id.toString()
									)}
									disabled={isDisabled}
								/>
							}
							label={item.option_text}
							className={styles.inner_radio_check}
							style={{
								outline: selectedValues.includes(
									item.option_id.toString()
								)
									? "1px solid #BE0303"
									: "",
							}}
						/>
					);
				})}
			</FormGroup>
			{hasError && (
				<small className="text-danger font-light italic">
					{errorMessage}
				</small>
			)}
		</label>
	);
};

export default CheckBoxesInput;
