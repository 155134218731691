import { Radio, RadioGroup, FormControlLabel } from "@mui/material";
import styles from "./input.module.css";

const RadioButtons = (props) => {
	const { items, question, field, hasError, errorMessage, extraInfo } = props;

	return (
		<label className={styles.inner_form_label}>
			<p>{question}</p>
			<small>
				{extraInfo}
			</small>
			<RadioGroup
				{...field}
				style={{ display: "block", width: "100%", marginTop: "24px" }}
			>
				{items.map((item) => (
					<FormControlLabel
						key={item.option_id}
						value={item.option_id}
						control={<Radio />}
						label={item.option_text}
						className={styles.inner_radio_check}
						style={{
							outline:
								field?.value === item.option_id.toString()
									? "1px solid #BE0303"
									: "",
						}}
					/>
				))}
			</RadioGroup>
			{hasError && <small className="text-danger">{errorMessage}</small>}
		</label>
	);
};

export default RadioButtons;
