import { useMutation, useQuery } from "react-query";
import { submitSurvey, validateSurveyCode } from "../services/mis-survey-service";

export const useValidateCode = (survey_code, shouldValidate) => {
	const response = useQuery({
		queryKey: [`survey-code-${survey_code}`],
		queryFn: () => validateSurveyCode(survey_code),
		enabled: shouldValidate,
	});
    

	return {
		isSuccess: response.isSuccess,
		isError: response.isError,
		isLoading: response.isLoading,
		data: response.data,
		error: response.error,
	};
};

export const useSubmitSurveyData = (options) => {
	const mutation = useMutation({
		mutationFn: (surveyFormData) =>
			submitSurvey(surveyFormData),
		onSuccess: (data) => {
			options.onSuccess(data);
		},
		onError: (error) => {
			options.onError(error);
		},
	});

	return {
		submitSurvey: mutation.mutate,
		isSuccess: mutation.isSuccess,
		isError: mutation.isError,
		isLoading: mutation.isLoading,
	};
};
