import React from 'react'
import styles from "./banner.module.css"

const Banner = ({title, description, imageURL}) => {
  return (
		<div className={styles.banner_container}>
			<h2>{title}</h2>
			<p>{description}</p>
			<img src={imageURL} alt={title} />

		</div>
  );
}

export default Banner
