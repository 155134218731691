import React, { useEffect } from "react";
import { IoIosArrowRoundForward } from "react-icons/io";
import { HiOutlineShieldCheck } from "react-icons/hi2";
import { FaCheck } from "react-icons/fa6";
import styles from "./thankyou.module.css";
import { useSurveySectionContent } from "../../../../context/survey-track/SurveySectionContext";
import { misSurveySection } from "../../../../utils/constants";
import {
	STORAGE_KEY,
	useSurveyFormContext,
} from "../../../../context/survey-form-data/SurverFormContext";
import { Link } from "react-router-dom";
import confettiIcon from "../../../../images/headerimges/mis-survey/confetti.svg";

const Thankyou = () => {
	const { currentSurveySection } = useSurveySectionContent();
	const { dispatch: dispatchSurvey } = useSurveyFormContext();
	const showFinalReward =
		currentSurveySection?.formCompletedAt === misSurveySection.FEEDBACK;

	const endSurvey = () => {
		dispatchSurvey({ type: "CLEAR" });
		sessionStorage.removeItem("::dc::us-mis");
		sessionStorage.removeItem("::agent::us-mis");
		sessionStorage.removeItem("::channel::us-mis");
		sessionStorage.removeItem(STORAGE_KEY);
	};

	useEffect(() => {
		endSurvey();
		window.scrollTo({ top: 0, behavior: "smooth" });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<section className={styles.thankyou_container}>
			<img src={confettiIcon} alt="confetti" />

			{showFinalReward && (
				<section className={styles.thankyou_note}>
					<p>
						<span className={styles.congrats}>
							Congratulations!
						</span>{" "}
						You have Earned
					</p>

					<h2>₦1,000 Naira airtime</h2>
					<p>
						You will be credited{" "}
						<span className={styles.highlight}>250 Naira</span> to
						your number now!
					</p>
				</section>
			)}

			<section className={styles.thankyou_other_section}>
				<h3>
					<HiOutlineShieldCheck /> About Us
				</h3>
				<p>
					At <span className={styles.highlight}>Sproxil</span>, we
					help protect lives by ensuring access to safe, authentic
					products. Our technology lets you verify medications and
					essential goods, preventing counterfeits.
				</p>

				<div className={styles.sproxil_usp}>
					<h3>
						<HiOutlineShieldCheck /> Stay Safe with{" "}
						<span className={styles.highlight}>Sproxil</span>!
					</h3>

					<p>
						<FaCheck />
						Verify your medicines instantly with our
						scratch-and-text authentication system
					</p>
					<p>
						<FaCheck />
						Shop with confidence knowing your health and safety are
						protected
					</p>
					<p>
						<FaCheck />
						Join millions of people who trust Sproxil for product
						verification and consumer protection!
					</p>
				</div>

				<Link to={"https://sproxil.com/about-us/"}>
					Learn more about Sproxil{" "}
					<IoIosArrowRoundForward color="white" size={24} />
				</Link>
			</section>
		</section>
	);
};

export default Thankyou;
