import { DatePicker } from "@mui/x-date-pickers";
import { useEffect, useState } from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import styles from "./input.module.css"

const DOBPicker = ({ onDOBChange }) => {
	const [year, setYear] = useState();
	const [age, setAge] = useState(null);
	const [yearValue, setYearValue] = useState(null);
	const [unknownYear, setUnknownYear] = useState(false);
    const currentYear = new Date().getFullYear();

	const handleYearChange = (newValue) => {
		setYear(newValue);
        if(newValue) {
            const selectedYear = newValue.$y;
			const calculatedAge = currentYear - selectedYear;
			setAge(calculatedAge);
        }
	};

	const handleYearDisableChange = (
		event
	) => {
		setUnknownYear(event.target.checked);
	};


    useEffect(() => {
        if (yearValue && age) {
			onDOBChange(age, yearValue);
		}
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [year, age])

    useEffect(() => {
        if(unknownYear) {
            setYearValue("I don't know but I am older than 15");
            setAge(1)
        }

    }, [unknownYear]);


	return (
		<div className={styles.date_picker_wrapper}>
			<DatePicker
				views={["year"]}
				value={year}
				onChange={(newValue) => {
					if (newValue === null) {
						setYearValue(null);
						setAge(null);
						setYear(null);
						return;
					}
					if (newValue) {
						const yearString = newValue.$y.toString();
						setYearValue(yearString);
						handleYearChange(newValue);
					}
				}}
				disabled={unknownYear}
				shouldDisableYear={(date) => date.year() > currentYear - 15}
				slotProps={{
					textField: {
						sx: {
							height: "50px",
							minHeight: "50px",
							backgroundColor: "transparent !important",
							"& .MuiOutlinedInput-root": {
								height: "50px",
								minHeight: "50px",
								borderRadius: "10px",
								paddingRight: "30px",
							},
							"& .MuiInputBase-input": {
								padding: "8px 8px",
								fontSize: "0.875rem",
							},
							"& .MuiInputLabel-root": {
								fontSize: "0.75rem",
								top: "-4px",
							},
							"& .MuiSvgIcon-root": {
								fontSize: "28px",
								width: "28px",
								height: "28px",
								fill: "#BE0303",
								marginTop: "8px",
							},
							"& .MuiIconButton-root": {
								padding: "4px",
								width: "32px",
								height: "32px",
								backgroundColor: "transparent !important",
								"&:hover": {
									backgroundColor: "transparent !important",
								},
							},
						},
					},
				}}
				sx={{
					"& .MuiOutlinedInput-root": {
						height: "50px",
						minHeight: "50px",
						paddingRight: "30px",
						borderRadius: "16px",
					},
					"& .MuiInputBase-input": {
						fontSize: "0.875rem",
						padding: "8px 8px",
					},
					"& .MuiSvgIcon-root": {
						fontSize: "28px",
						width: "28px",
						height: "28px",
						fill: "#BE0303",
						marginTop: "8px",
					},
					"& .MuiIconButton-root": {
						padding: "4px",
						width: "32px",
						height: "32px",
						backgroundColor: "transparent !important",
						"&:hover": {
							backgroundColor: "#BE0303 !important",
						},
					},
				}}
			/>
			<FormControlLabel
				control={
					<Checkbox
						checked={unknownYear}
						onChange={handleYearDisableChange}
						disabled={
							Boolean(yearValue) &&
							yearValue !== "I don't know but I am older than 15"
						}
					/>
				}
				label="I don't know but I am older than 15"
			/>
		</div>
	);
};

export default DOBPicker;
