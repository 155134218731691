import { createContext, useContext, useEffect, useReducer } from "react";
import SurveyDataReducer from "./SurveyFormReducer";
import CryptoJS from "crypto-js";
import { SURVEY_FORM_INITIAL_DATA } from "../../utils/static-data";

const InitialStateData = {
	respondent: {
		phone_number: "",
		longitude: "",
		latitude: "",
		age: 0,
		year_of_birth: "",
		gender: "",
		state: "",
		lga: "",
		town: "",
	},
	surveyResponses: SURVEY_FORM_INITIAL_DATA,
	survey_id: 0,
	channel: "",
	agent: null,
	survey_code: "",
};

export const STORAGE_KEY = "persist::MIS::Survey";
const SECRET_KEY = "9::e9b2A::c8f7IN::a6BE498||SM";

const encryptData = (data) => {
	const stringData = JSON.stringify(data);
	return CryptoJS.AES.encrypt(stringData, SECRET_KEY).toString();
};

const decryptData = (encryptedData) => {
	const bytes = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
	const decryptedString = bytes.toString(CryptoJS.enc.Utf8);
	return JSON.parse(decryptedString);
};

const loadInitialState = () => {
	const savedData = sessionStorage.getItem(STORAGE_KEY);
	if (savedData) {
		try {
			return { surveyFormData: decryptData(savedData) };
		} catch (error) {
			console.error("Failed to decrypt survey data:", error);
		}
	}
	return { surveyFormData: InitialStateData };
};

const SurveyFormDataContext = createContext({
	surveyFormData: InitialStateData,
	dispatch: () => null,
});

const SurveyFormProvider = ({ children }) => {
	const [state, dispatch] = useReducer(SurveyDataReducer, loadInitialState());

	useEffect(() => {
		if (state.surveyFormData) {
			try {
				const encryptedData = encryptData(state.surveyFormData);
				sessionStorage.setItem(STORAGE_KEY, encryptedData);
			} catch (error) {
				console.error("Failed to encrypt survey data:", error);
			}
		}
	}, [state.surveyFormData]);

	return (
		<SurveyFormDataContext.Provider
			value={{ surveyFormData: state.surveyFormData, dispatch }}
		>
			{children}
		</SurveyFormDataContext.Provider>
	);
};

const useSurveyFormContext = () => {
	const context = useContext(SurveyFormDataContext);
	if (!context) {
		throw new Error(
			"useSurveyFormContext must be used within a SurveyFormProvider"
		);
	}
	return context;
};

export { SurveyFormProvider, useSurveyFormContext };
