import { createContext, useContext, useEffect, useReducer } from "react";
import SurveySectionReducer from "./SurveySectionReducer";

const INITIAL_SECTION_STATE = {
	currentSurveySection: JSON.parse(sessionStorage.getItem("surveyTrack")),
};

export const SurveySectionContext = createContext({
	currentSurveySection: INITIAL_SECTION_STATE.currentSurveySection,
	dispatch: () => null,
});

export const SurveySectionProvider = ({ children }) => {
	const [state, dispatch] = useReducer(
		SurveySectionReducer,
		INITIAL_SECTION_STATE
	);

	useEffect(() => {
		if (state.currentSurveySection) {
			sessionStorage.setItem(
				"surveyTrack",
				JSON.stringify(state.currentSurveySection)
			);
		}
	}, [state.currentSurveySection]);

	return (
		<SurveySectionContext.Provider
			value={{
				currentSurveySection: state.currentSurveySection,
				dispatch,
			}}
		>
			{children}
		</SurveySectionContext.Provider>
	);
};

export const useSurveySectionContent = () => {
	const context = useContext(SurveySectionContext);
	if (!context) {
		throw new Error("useForm must be used within a SurveySectionProvider");
	}
	return context;
};
