import React from "react";
import { SurveySectionProvider } from "../context/survey-track/SurveySectionContext";
import Context from "../Context";
import { SurveyFormProvider } from "../context/survey-form-data/SurverFormContext";
import { ThemeProvider } from "@mui/material";
import { themeConfig } from "../utils/muiTheme";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Toaster } from "react-hot-toast";

const AppProviders = ({ children }) => {
	return (
		<Context>
			<SurveyFormProvider>
				<SurveySectionProvider>
					<ThemeProvider theme={themeConfig}>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<Toaster
								position="top-center"
								reverseOrder={false}
								toastOptions={{
									style: {
										color: "#FFFFFF",
									},
									success: {
										style: {
											background: "#006600",
										},
									},
									error: {
										style: {
											background: "#BE0303",
										},
									},
								}}
							/>
							{children}
						</LocalizationProvider>
					</ThemeProvider>
				</SurveySectionProvider>
				;
			</SurveyFormProvider>
		</Context>
	);
};

export default AppProviders;
