import surveyInstance from "..";

const {get, post} = surveyInstance

export const validateSurveyCode = async (survey_code) => {
	const response = get(
		`/survey/code?survey_code=${survey_code}`
	);
	return (await response).data;
};

export const submitSurvey = async (payload) => {
	const response = post("/survey", payload);
	return (await response).data;
};
