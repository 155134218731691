import React, { useEffect, useState } from "react";
import styles from "./welcome.module.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IoIosArrowRoundForward } from "react-icons/io";
import { useSurveySectionContent } from "../../../../context/survey-track/SurveySectionContext";
import { misSurveySection } from "../../../../utils/constants";
import { useValidateCode } from "../../../../api/hooks";
import { useSurveyFormContext } from "../../../../context/survey-form-data/SurverFormContext";
import toast from "react-hot-toast";
import { Backdrop, CircularProgress } from "@mui/material";
import nmepImage from "../../../../images/headerimges/mis-survey/nmep.svg";
import gatesfoundationImage from "../../../../images/headerimges/mis-survey/gatesfoundation.jpeg";
import sproxillogo from "../../../../images/headerimges/mis-survey/sproxillogo.png";
import clockIcon from "../../../../images/headerimges/mis-survey/clock.svg";
import securedIcon from "../../../../images/headerimges/mis-survey/security.svg";
import rewardIcon from "../../../../images/headerimges/mis-survey/money.svg";
const WelcomePage = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { dispatch } = useSurveySectionContent();
	const { surveyFormData, dispatch: dispatchSurvey } = useSurveyFormContext();
	const [shouldValidate, setShouldValidate] = useState(false);
	const sessionSurveyCode = sessionStorage.getItem("::dc::us-mis") || "";
	const sessionAgentNumber = sessionStorage.getItem("::agent::us-mis") || "";
	const sessionChannel = sessionStorage.getItem("::channel::us-mis") || "";
	const { data, isLoading, isError, isSuccess, error } = useValidateCode(
		sessionSurveyCode,
		shouldValidate
	);

	const proceedToSurvey = () => {
		if (sessionSurveyCode) {
			setShouldValidate(true);
		}
	};

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const urlSurveyCode = queryParams.get("c") ?? "";
		const agentNumber = queryParams.get("userId") ?? "";
		const surveyChannel = queryParams.get("channel") ?? "";
		if (urlSurveyCode) {
			sessionStorage.setItem("::dc::us-mis", urlSurveyCode);
			sessionStorage.setItem("::agent::us-mis", agentNumber);
			sessionStorage.setItem("::channel::us-mis", surveyChannel);
			queryParams.delete("c");
			queryParams.delete("userId");
			queryParams.delete("channel");
			navigate(`${location.pathname}?${queryParams.toString()}`, {
				replace: true,
			});
		}
	}, [location, navigate]);

	useEffect(() => {
		if (isSuccess) {
			dispatchSurvey({
				type: "UPDATE_RESPONDENT",
				payload: {
					...surveyFormData?.respondent,
					phone_number: data.data?.phone_number,
				},
			});

			setTimeout(() => {
				dispatchSurvey({
					type: "UPDATE_SURVEY_PARAMS",
					payload: {
						survey_id: data.data?.survey_id,
						channel:
							sessionChannel !== "" ? sessionChannel : "direct",
						agent:
							sessionAgentNumber !== ""
								? sessionAgentNumber
								: null,
						survey_code: data.data?.survey_code,
					},
				});
			}, 1000);

			setTimeout(() => {
				dispatch({
					type: "UPDATE",
					payload: {
						currentSection: misSurveySection.DEMOGRAPHICS,
						showConfetti: false,
						formCompletedAt: null,
					},
				});
			}, 2000);
		}

		if (isError) {
			toast.error(
				error?.response?.data?.message ||
					"You do not have access to this survey"
			);
			setShouldValidate(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSuccess, isError]);

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	}, []);

	return (
		<section className={styles.container}>
			<Backdrop
				sx={(theme) => ({
					color: "#fff",
					zIndex: theme.zIndex.drawer + 3,
				})}
				open={!isError && isLoading}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
			<div className={styles.image_wrapper}>
				<img src={sproxillogo} alt="sproxil" />
			</div>

			<div className={styles.introduction_wrapper}>
				<h2>Help Shape Nigeria's Fight Against Malaria</h2>
				<p>
					Share your experience with malaria to strengthen prevention
					and treatment in your community. Your insights will directly
					impact healthcare initiatives across Nigeria.
				</p>

				<section className={styles.introduction_icons}>
					<div className={styles.survey_icon_summary}>
						<img src={clockIcon} alt="fast survey" />
						<p>
							Quick <span>5-minute</span> survey
						</p>
					</div>
					<div className={styles.survey_icon_summary}>
						<img src={securedIcon} alt="fast survey" />
						<p>
							100% <span>confidential responses</span>
						</p>
					</div>
					<div className={styles.survey_icon_summary}>
						<img src={rewardIcon} alt="fast survey" />
						<p>
							Earn <span>₦1,000 Naira airtime</span> instantly
						</p>
					</div>
				</section>

				<section className={styles.consent}>
					<p>
						Starting survey indicates that you agree to the{" "}
						<Link to={"https://sproxil.com/terms-of-service/"}>
							Terms & Conditions
						</Link>
					</p>

					<button
						type="button"
						onClick={proceedToSurvey}
						disabled={isLoading || !sessionSurveyCode}
					>
						Start Survey & Get Your N1000{" "}
						<IoIosArrowRoundForward color="white" size={24} />
					</button>
					<p>Together, we can defeat malaria in Nigeria</p>
				</section>

				<section className={styles.sponsors}>
					<img src={nmepImage} alt="nmep" />
					<img src={gatesfoundationImage} alt="gates foundation" />
				</section>
			</div>
		</section>
	);
};

export default WelcomePage;
