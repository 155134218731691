export const SURVEY_FORM_INITIAL_DATA = {
	quiz_1: "",
	quiz_2: "",
	quiz_3: "",
	quiz_4: "",
	quiz_5: "",
	quiz_6: "",
	quiz_7: "",
	quiz_8: [],
	quiz_8_other_39: "",
	quiz_9: "",
	quiz_10: "",
	quiz_11: "",
	quiz_11_other_67: "",
	quiz_12: "",
	quiz_13: "",
	quiz_14: "",
	quiz_14_other_91: "",
	quiz_15: "",
	quiz_15_other_109: "",
	quiz_16: "",
	quiz_17: "",
	quiz_18: "",
	quiz_19: "",
	quiz_20: "",
	quiz_21: "",
	quiz_22: "",
	quiz_23: "",
	quiz_24: "",
};
