import { createTheme } from "@mui/material/styles";

export const themeConfig = createTheme({
	components: {
		MuiRadio: {
			styleOverrides: {
				root: {
					color: "#BE0303 !important",
					"&.Mui-checked": {
						color: "#BE0303",
					},
				},
			},
		},
		MuiCheckbox: {
			styleOverrides: {
				root: {
					color: "#BE0303", // Default checkbox color
					"&.Mui-checked": {
						color: "#BE0303", // Color when the checkbox is checked
					},
					"&:hover": {
						backgroundColor: "rgba(190, 3, 3, 0.1)", // Light hover effect
					},
				},
			},
		},
		MuiSelect: {
			styleOverrides: {
				root: {
					color: "#BE0303", // Customize the text color of the selected item
					borderRadius: "8px",
					padding: "4px 4px",
					border: "none",
					boxShadow: "none",
					"&.MuiOutlinedInput-root": {
						borderRadius: "4px", // Ensure the dropdown is also rounded
						border: "none",
						boxShadow: "none",
					},
					"&:before, &:after": {
						display: "none", // Removes bottom line
					},
				},
				icon: {
					color: "#BE0303", // Customize the dropdown icon color
				},
				outlined: {
					borderColor: "transparent !important", // Customize the border color of the select input
					"&:hover": {
						border: "none",
						boxShadow: "none",
					},
				},
				filled: {
					backgroundColor: "#F2F2F2", // Customize the background color of the filled select input
					border: "none",
					boxShadow: "none",
				},
			},
		},
		MuiInputLabel: {
			styleOverrides: {
				root: {
					color: "#BE0303", // Label color
					backgroundColor: "#F2F2F2",
					padding: "4px",
					borderRadius: "4px",
					"&.Mui-focused": {
						color: "#BE0303", // Label color when focused
						border: "none",
					},
					"&.MuiFormLabel-filled": {
						color: "#BE0303",
						backgroundColor: "#F2F2F2",
						border: "0",
					},
				},
			},
		},
		MuiInputBase: {
			styleOverrides: {
				root: {
					color: "#333",
					boxShadow: "none", // Removes box shadow for base input
					"&:hover": {
						boxShadow: "none", // Removes box shadow on hover
					},
					"&.Mui-focused": {
						boxShadow: "none", // Removes box shadow on focus
					},
				},
				input: {
					color: "#333",
					padding: "12px 16px",
				},
			},
		},
		MuiFormLabel: {
			styleOverrides: {
				root: {
					color: "#BE0303",
					"&.Mui-focused": {
						color: "#BE0303",
					},
				},
			},
		},
		MuiTextField: {
			styleOverrides: {
				root: {
					backgroundColor: "#E5E7EB",
					borderRadius: "4px",
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					backgroundColor: "#F2F2F2", // background for the outlined input
					borderRadius: "2px", // Round corners for the outlined input field
					padding: "6px",
					"& fieldset": {
						borderColor: "transparent !important", // Removes border
					},
					"&:hover fieldset": {
						borderColor: "transparent !important",
					},
					"&.Mui-focused fieldset": {
						borderColor: "transparent !important",
					},
				},
			},
		},
	},

	palette: {
		primary: {
			main: "#6D0000",
			light: "#BE0303",
			dark: "#6D0000",
			contrastText: "#F2F2F2",
		},
	},
});
