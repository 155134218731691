const processSurveyData = (completeSurveyObj, surveyCallBackFn) => {
    const dataKeys = Object.keys(completeSurveyObj);

	const responses = dataKeys
		.filter((key) => !key.includes("other"))
		.flatMap((eachDataKey) => {
			const userResponse = completeSurveyObj[eachDataKey];
			const quizId = eachDataKey.split("_")[1];
			const isArray = Array.isArray(userResponse);
			if (isArray) {
				return userResponse.map((eachResponse) => {
					const otherKey = `${eachDataKey}_other_${eachResponse}`;
					const hasOtherText = completeSurveyObj[otherKey];
					return {
						quiz_id: Number(quizId),
						option_id: Number(eachResponse),
						text_response: Boolean(hasOtherText)
							? hasOtherText
							: "NULL",
					};
				});
			} else {
				const otherKey = `${eachDataKey}_other_${userResponse}`;
				const hasOtherText = completeSurveyObj[otherKey];
				return {
					quiz_id: Number(quizId),
					option_id: Number(userResponse),
					text_response: Boolean(hasOtherText)
						? hasOtherText
						: "NULL",
				};
			}
		});
    return surveyCallBackFn(responses)
}

export { processSurveyData };
