
const SurveyDataReducer = (state, { payload, type }) => {
	switch (type) {
		case "UPDATE_SURVEY_PARAMS": {
            console.log("update params reducer", type)
            console.log("update params payload", payload);
			return {
				surveyFormData: {
					...state.surveyFormData,
					survey_id: payload.survey_id,
					channel: payload.channel,
					agent: payload.agent,
					survey_code: payload.survey_code,
				},
			};
		}
		case "UPDATE_RESPONDENT": {
			return {
				surveyFormData: {
					...state.surveyFormData,
					respondent: payload,
				},
			};
		}
		case "UPDATE_RESPONSES": {
			return {
				surveyFormData: {
					...state.surveyFormData,
					surveyResponses: payload,
				},
			};
		}
		case "CLEAR": {
			return {
				surveyFormData: null,
			};
		}

		default:
			return state;
	}
};

export default SurveyDataReducer;
