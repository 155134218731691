import React, { useEffect } from "react";
import styles from "./sectionstyle.module.css";
import Banner from "./banner/Banner";
import { IoIosArrowRoundForward } from "react-icons/io";
import { useSurveySectionContent } from "../../../context/survey-track/SurveySectionContext";
import { misSurveySection } from "../../../utils/constants";
import { Controller, useForm } from "react-hook-form";
import RadioButtons from "../RadioInput";
import { zodResolver } from "@hookform/resolvers/zod";
import { useSurveyFormContext } from "../../../context/survey-form-data/SurverFormContext";
import { preventionSchema } from "../../../validators/mis/demographics";
import CheckBoxesInput from "../CheckBoxInput";
import {
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from "@mui/material";
import preventionImage from "../../../images/headerimges/mis-survey/prevention-banner.svg";

const Prevention = () => {
	const { dispatch } = useSurveySectionContent();
	const { surveyFormData, dispatch: dispatchSurvey } = useSurveyFormContext();

	const {
		control,
		register,
		handleSubmit,
		watch,
		setValue,
		formState: { errors },
	} = useForm({
		resolver: zodResolver(preventionSchema),
		defaultValues: surveyFormData?.surveyResponses,
	});
	const onSubmit = (data) => {
		dispatchSurvey({
			type: "UPDATE_RESPONSES",
			payload: { ...surveyFormData?.surveyResponses, ...data },
		});
		dispatch({
			type: "UPDATE",
			payload: {
				currentSection: misSurveySection.TREATMENT,
				showConfetti: false,
				formCompletedAt: misSurveySection.PREVENTION,
				rewardPrice: "120",
			},
		});
	};
	const formData = watch();

	const mosquitoRepellantsOptions = [
		{ option_text: "Coils", option_id: 35 },
		{
			option_text: "Sprays/Insecticides",
			option_id: 36,
		},
		{ option_text: "Creams", option_id: 37 },
		{
			option_text: "Electronic devices",
			option_id: 38,
		},
		{ option_text: "Others", option_id: 39 },
		{ option_text: "None", option_id: 40 },
	];

	const firstSeekMalariaTreatmentOptions = [
		{ option_text: "Government hospital", option_id: 41 },
		{
			option_text: "Private hospital/clinic",
			option_id: 42,
		},
		{ option_text: "NGO hospital", option_id: 43 },
		{ option_text: "Mobile clinic", option_id: 44 },
		{
			option_text: "Private doctor (no hospital visit)",
			option_id: 45,
		},
		{
			option_text: "Community Nurse/ Health worker (No hospital visit)",
			option_id: 46,
		},
		{ option_text: "Pharmacy", option_id: 47 },
		{
			option_text: "Local drug store/Chemist",
			option_id: 48,
		},
		{
			option_text: "Mobile drug seller",
			option_id: 49,
		},
		{
			option_text: "Traditional healer",
			option_id: 50,
		},
		{ option_text: "Religious healer", option_id: 51 },
	];

	const nearestHealthcareOptions = [
		{ option_text: "Less than 30 mins", option_id: 52 },
		{ option_text: "30 mins - 1 hour;", option_id: 53 },
		{ option_text: "More than 1 hour", option_id: 54 },
		{ option_text: "I don't know ", option_id: 55 },
	];

	const healthcareTransportCostOptions = [
		{ option_text: "Free", option_id: 56 },
		{ option_text: "N1 - N999", option_id: 57 },
		{ option_text: "N1,000 - N1,999 ", option_id: 58 },
		{ option_text: "N2,000 - N2,999", option_id: 59 },
		{ option_text: "N3,000 - N3,999", option_id: 60 },
		{ option_text: "N4,000 - N4,999", option_id: 61 },
		{ option_text: "N5,000 - N5,999", option_id: 62 },
		{ option_text: "N6,000 - N6,999", option_id: 63 },
		{ option_text: "N7000 - N7,999", option_id: 64 },
		{ option_text: "N8,000 - N8,999", option_id: 65 },
		{ option_text: "N9,000 - N9,999", option_id: 66 },
		{ option_text: "Above N10,000", option_id: 67 },
		{ option_text: "I don't know", option_id: 68 },
	];

	const hasOtherRepellants = formData?.quiz_8?.includes("39");
	const hasOtherTransportCost = formData?.quiz_11?.includes("67");

	useEffect(() => {
		if (!hasOtherRepellants) {
			setValue("quiz_8_other_39", "");
		}
		if (!hasOtherTransportCost) {
			setValue("quiz_11_other_67", "");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hasOtherRepellants, hasOtherTransportCost]);

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	}, []);

	return (
		<section className={styles.container}>
			<Banner
				title={"Malaria Prevention & Control"}
				description={
					"We want to understand household malaria prevention habits from you."
				}
				imageURL={preventionImage}
			/>

			<form onSubmit={handleSubmit(onSubmit)}>
				<div className={styles.form_earning}>
					<p>You Earn</p>
					<h3>₦1,000 Naira</h3>
					<p>when you complete this survey</p>
				</div>

				<Controller
					name="quiz_5"
					control={control}
					render={({ field }) => (
						<RadioButtons
							field={field}
							question="Did you or anyone in your household sleep under a mosquito net last night?"
							items={[
								{ option_text: "Yes", option_id: 26 },
								{ option_text: "No", option_id: 27 },
								{ option_text: "I don't know", option_id: 28 },
							]}
							{...(register("quiz_5"), { required: true })}
							hasError={Boolean(errors.quiz_5)}
							errorMessage={errors?.quiz_5?.message || ""}
						/>
					)}
				/>
				{formData.quiz_5 === "26" && (
					<Controller
						name="quiz_6"
						control={control}
						render={({ field }) => (
							<RadioButtons
								field={field}
								question="Do you know if that net is an insecticide treated net?"
								items={[
									{
										option_text: "Yes -  It is",
										option_id: 29,
									},
									{
										option_text: "No - It is not",
										option_id: 30,
									},
									{
										option_text: "I don't know",
										option_id: 31,
									},
								]}
								{...(register("quiz_6"), { required: true })}
								hasError={Boolean(errors.quiz_6)}
								errorMessage={errors?.quiz_6?.message || ""}
							/>
						)}
					/>
				)}

				<Controller
					name="quiz_7"
					control={control}
					render={({ field }) => (
						<RadioButtons
							field={field}
							question="In the past 12 months, has anyone entered your home to spray the interior walls for mosquito control?"
							items={[
								{ option_text: "Yes", option_id: 32 },
								{ option_text: "No", option_id: 33 },
								{
									option_text: "I don't know what it is ",
									option_id: 34,
								},
							]}
							{...(register("quiz_7"), { required: true })}
							hasError={Boolean(errors.quiz_7)}
							errorMessage={errors?.quiz_7?.message || ""}
						/>
					)}
				/>

				<div>
					<Controller
						name="quiz_8"
						control={control}
						render={({ field }) => (
							<CheckBoxesInput
								field={field}
								setValue={setValue}
								question="Do you use any of these mosquito repellent methods?"
								items={mosquitoRepellantsOptions}
								{...(register("quiz_8"), { required: true })}
								hasError={Boolean(errors.quiz_8)}
								errorMessage={errors?.quiz_8?.message || ""}
							/>
						)}
					/>
					{formData?.quiz_8?.includes("39") && (
						<div className={styles.other_text_input_wrapper}>
							<small
								style={{
									display: "block",
									marginBottom: "8px",
								}}
							>
								State the other types of mosquito repellent
								methods you use
							</small>
							<TextField
								id="quiz_8_other_39"
								label="enter other repellants here"
								{...register("quiz_8_other_39", {
									required: true,
								})}
								variant="outlined"
								size="small"
								className={styles.other_text_input}
							/>
							{errors.quiz_8_other_39 && (
								<small className="text-danger">
									{errors.quiz_8_other_39.message}
								</small>
							)}
						</div>
					)}
				</div>

				<div className={styles.form_education}>
					<p>
						Where do you usually go first to seek malaria treatment?
					</p>
					<FormControl fullWidth>
						<InputLabel id="quiz_9_label">Choose one</InputLabel>
						<Controller
							name="quiz_9"
							control={control}
							render={({ field }) => (
								<Select
									{...field}
									labelId="quiz_9_label"
									id="quiz_9"
									variant="filled"
									size="small"
								>
									{firstSeekMalariaTreatmentOptions.map(
										(level) => (
											<MenuItem
												key={level.option_id}
												value={level.option_id.toString()}
											>
												{level.option_text}
											</MenuItem>
										)
									)}
								</Select>
							)}
						/>
						{errors.quiz_9 && (
							<small className="text-danger">
								{errors.quiz_9.message}
							</small>
						)}
					</FormControl>
				</div>

				<div className={styles.form_education}>
					<p>
						How long does it take to reach the nearest healthcare
						facility to seek malaria treatment?
					</p>
					<FormControl fullWidth>
						<InputLabel id="quiz_10_label">Choose one</InputLabel>
						<Controller
							name="quiz_10"
							control={control}
							render={({ field }) => (
								<Select
									{...field}
									labelId="quiz_10_label"
									id="quiz_10"
									variant="filled"
									size="small"
								>
									{nearestHealthcareOptions.map((level) => (
										<MenuItem
											key={level.option_id}
											value={level.option_id.toString()}
										>
											{level.option_text}
										</MenuItem>
									))}
								</Select>
							)}
						/>
						{errors.quiz_10 && (
							<small className="text-danger">
								{errors.quiz_10.message}
							</small>
						)}
					</FormControl>
				</div>

				<div className={styles.form_education}>
					<p>
						How much do you spend on transport cost (to and fro) to
						visit the nearest healthcare facility to seek malaria
						treatment?
					</p>
					<FormControl fullWidth>
						<InputLabel id="quiz_11_label">Choose one</InputLabel>
						<Controller
							name="quiz_11"
							control={control}
							render={({ field }) => (
								<Select
									{...field}
									labelId="quiz_11_label"
									id="quiz_11"
									variant="filled"
									size="small"
								>
									{healthcareTransportCostOptions.map(
										(level) => (
											<MenuItem
												key={level.option_id}
												value={level.option_id.toString()}
											>
												{level.option_text}
											</MenuItem>
										)
									)}
								</Select>
							)}
						/>
						{errors.quiz_11 && (
							<small className="text-danger">
								{errors.quiz_11.message}
							</small>
						)}
					</FormControl>
					{formData?.quiz_11?.includes("67") && (
						<div className={styles.other_text_input_wrapper}>
							<TextField
								id="quiz_11_other_67"
								label="enter other prices here"
								{...register("quiz_11_other_67", {
									required: true,
								})}
								variant="outlined"
								size="small"
								className={styles.other_text_input}
							/>
							{errors.quiz_11_other_67 && (
								<small className="text-danger">
									{errors.quiz_11_other_67.message}
								</small>
							)}
						</div>
					)}
				</div>

				<button type="submit">
					Submit <IoIosArrowRoundForward color="white" size={24} />
				</button>
			</form>
		</section>
	);
};

export default Prevention;
