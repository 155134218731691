import { useState, useEffect } from "react";
import {
	Select,
	MenuItem,
	InputLabel,
	FormControl,
	TextField,
} from "@mui/material";
import Geocode from "react-geocode";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import useGeolocation from "../../utils/useGeolocation";
import { ngSates } from "../../utils";
import styles from "./input.module.css";

const CountryPicker = ({ onLocationChange }) => {
	const { coordinates, error, gLocation, isLoading } = useGeolocation();
	const [inputLocation, setInputLocation] = useState({
		state: "",
		lga: "",
		town: "",
	});
	const [isLoadingFromInput, setIsLoadingFromInput] = useState(false);
	console.log({ error, isLoading });

	useEffect(() => {
		if (coordinates && !error && gLocation) {
			onLocationChange({
				latitude: coordinates.latitude,
				longitude: coordinates.longitude,
				userLocation: gLocation,
			});
		} else if (error) {
			onLocationChange({
				latitude: null,
				longitude: null,
				userLocation: null,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [coordinates, gLocation]);

	const handleSelectState = (e) => {
		setInputLocation((prev) => ({ ...prev, state: e.target.value }));
	};

	const handleSelectLocal = (e) => {
		setInputLocation((prev) => ({ ...prev, lga: e.target.value }));
	};

	const handleChangeTown = (e) => {
		setInputLocation((prev) => ({ ...prev, town: e.target.value }));
	};

	// Reverse geocoding for the user's manual input
	useEffect(() => {
		const getUserLatLng = async () => {
			if (
				inputLocation.town &&
				inputLocation.lga &&
				inputLocation.state
			) {
				const address = `${inputLocation.town}, ${inputLocation.lga}, ${inputLocation.state}, Nigeria`;
				try {
					setIsLoadingFromInput(true);
					const response = await Geocode.fromAddress(address);
					const lat = response.results[0].geometry.location.lat;
					const lng = response.results[0].geometry.location.lng;

					onLocationChange({
						latitude: lat,
						longitude: lng,
						userLocation: inputLocation,
					});
					setIsLoadingFromInput(false);
				} catch (err) {
					console.error({ err });
					setIsLoadingFromInput(false);
					onLocationChange({
						latitude: null,
						longitude: null,
						userLocation: null,
					});
				}
			}
		};

		getUserLatLng();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inputLocation]);

	return (
		<div className={styles.country_picker}>
			<Backdrop
				sx={(theme) => ({
					color: "#fff",
					zIndex: theme.zIndex.drawer + 3,
				})}
				open={(!error && isLoading) || isLoadingFromInput}
			>
				<CircularProgress color="inherit" />
			</Backdrop>

			{((!isLoading && error) || gLocation?.town === "") && (
				<>
					<p>What is your town, LGA and state of residence?</p>

					<FormControl fullWidth>
						<InputLabel id="state">Choose State</InputLabel>
						<Select
							labelId="state"
							id="state"
							value={inputLocation.state}
							label="state"
							onChange={handleSelectState}
							variant="filled"
							size="small"
							MenuProps={{ disablePortal: true }}
						>
							{ngSates.map((state) => (
								<MenuItem
									key={state.state.name}
									value={state.state.name}
								>
									{state.state.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<FormControl fullWidth>
						<InputLabel id="lga">Choose LGA</InputLabel>
						<Select
							labelId="lga"
							id="lga"
							value={inputLocation.lga}
							label="lga"
							onChange={handleSelectLocal}
							variant="filled"
							size="small"
						>
							{ngSates
								.find(
									(state) =>
										state.state.name === inputLocation.state
								)
								?.state.locals.map((local) => (
									<MenuItem
										key={local.name}
										value={local.name}
									>
										{local.name}
									</MenuItem>
								))}
						</Select>
					</FormControl>
					<TextField
						id="town"
						label="Town"
						variant="outlined"
						size="small"
						className="py-8"
						value={inputLocation.town}
						onChange={handleChangeTown}
					/>
				</>
			)}
		</div>
	);
};

export default CountryPicker;
