import React, { useEffect, useState } from "react";
import styles from "./sectionstyle.module.css";
import Banner from "./banner/Banner";
import { IoIosArrowRoundForward } from "react-icons/io";
import { misSurveySection } from "../../../utils/constants";
import { useSurveySectionContent } from "../../../context/survey-track/SurveySectionContext";
import { Controller, useForm } from "react-hook-form";
import RadioButtons from "../RadioInput";
import { zodResolver } from "@hookform/resolvers/zod";
import { demographicsSchema } from "../../../validators/mis/demographics";
import { useSurveyFormContext } from "../../../context/survey-form-data/SurverFormContext";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import DOBPicker from "../YearPicker";
import CountryPicker from "../CountryPicker";
import demographicsImage from "../../../images/headerimges/mis-survey/demographics-banner.svg";

const Demographics = () => {
	const { dispatch } = useSurveySectionContent();
	const { surveyFormData, dispatch: dispatchSurvey } = useSurveyFormContext();
	const [userDOB, setUserDOB] = useState(null);

	const {
		control,
		register,
		setValue,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: zodResolver(demographicsSchema),
		defaultValues: surveyFormData?.surveyResponses,
	});

	const updateStateLocation = (payload) => {
		dispatchSurvey({
			type: "UPDATE_RESPONDENT",
			payload: {
				...surveyFormData?.respondent,
				longitude: payload.longitude.toString(),
				latitude: payload.latitude.toString(),
				state: payload.userLocation?.state || "",
				lga: payload.userLocation?.lga || "",
				town: payload.userLocation?.town || "",
			},
		});
	};

	const onSubmit = (data) => {
		if (userDOB?.age === 1 || userDOB?.age > 15) {
			dispatchSurvey({
				type: "UPDATE_RESPONDENT",
				payload: {
					...surveyFormData?.respondent,
					age: userDOB?.year === "" ? 0 : userDOB?.age,
					year_of_birth: userDOB?.year,
					gender: data.quiz_1 === "1" ? "Male" : "Female",
				},
			});
			setTimeout(() => {
				dispatchSurvey({
					type: "UPDATE_RESPONSES",
					payload: {
						...surveyFormData?.surveyResponses,
						quiz_1: data.quiz_1,
						quiz_2: data.quiz_2,
						quiz_3: data.quiz_3,
						quiz_4: data.quiz_4,
					},
				});
				dispatch({
					type: "UPDATE",
					payload: {
						currentSection: misSurveySection.PREVENTION,
						showConfetti: false,
						formCompletedAt: misSurveySection.DEMOGRAPHICS,
						rewardPrice: "60",
					},
				});
			}, 1500);
		} else {
			dispatch({
				type: "UPDATE",
				payload: {
					currentSection: misSurveySection.THANK_YOU,
					showConfetti: false,
					formCompletedAt: misSurveySection.DEMOGRAPHICS,
					rewardPrice: "",
				},
			});
			dispatchSurvey({ type: "CLEAR" });
		}
	};

	const schoolLevelOptions = [
		{ option_text: "Primary", option_id: 4 },
		{ option_text: "Secondary", option_id: 4 },
		{ option_text: "Tertiary", option_id: 5 },
		{ option_text: "Postgraduate", option_id: 6 },
		{
			option_text: "Vocational education",
			option_id: 7,
		},
		{ option_text: "No Formal Education", option_id: 8 },
	];

	const childrenUnderNineOptions = [
		{ option_text: "0", option_id: 9 },
		{ option_text: "1", option_id: 10 },
		{ option_text: "2", option_id: 11 },
		{ option_text: "3", option_id: 12 },
		{ option_text: "4", option_id: 13 },
		{ option_text: "5", option_id: 14 },
		{ option_text: "6", option_id: 15 },
		{ option_text: "7", option_id: 16 },
		{ option_text: "8", option_id: 17 },
		{ option_text: "more than 8", option_id: 18 },
	];

	const bedRoomOptions = [
		{
			option_text: "Open floor with no separate bedrooms",
			option_id: 19,
		},
		{ option_text: " 1", option_id: 20 },
		{ option_text: "2", option_id: 21 },
		{ option_text: "3", option_id: 22 },
		{ option_text: "4", option_id: 23 },
		{ option_text: "5", option_id: 24 },
		{ option_text: "more than 5", option_id: 25 },
	];

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	}, []);

	return (
		<section className={styles.container}>
			<Banner
				title={"Demographics"}
				description={
					"We need a few details to understand your background. Your responses help improve malaria prevention efforts."
				}
				imageURL={demographicsImage}
			/>

			<form onSubmit={handleSubmit(onSubmit)}>
				<div className={styles.form_earning}>
					<p>You Earn</p>
					<h3>₦1,000 Naira</h3>
					<p>when you complete this survey</p>
				</div>

				<div className={styles.form_education}>
					<p>In what year were you born?</p>
					<DOBPicker
						onDOBChange={(age, year) => {
							console.log({ age, year });
							setUserDOB({ age, year });
							setValue("age", age, {
								shouldValidate: true,
								shouldTouch: true,
								shouldDirty: true,
							});
						}}
					/>

					{errors.age && (
						<small className="text-danger">
							{errors.age.message}
						</small>
					)}
				</div>

				<Controller
					name="quiz_1"
					control={control}
					render={({ field }) => (
						<RadioButtons
							field={field}
							question="What is your gender?"
							items={[
								{ option_text: "Male", option_id: 1 },
								{ option_text: "Female", option_id: 2 },
							]}
							{...(register("quiz_1"), { required: true })}
							hasError={Boolean(errors.quiz_1)}
							errorMessage={errors?.quiz_1?.message || ""}
						/>
					)}
				/>

				<div className={styles.form_education}>
					<p>What is your highest level of education?</p>
					<FormControl fullWidth>
						<InputLabel id="quiz_2">Choose level</InputLabel>
						<Controller
							name="quiz_2"
							control={control}
							render={({ field }) => (
								<Select
									{...field}
									labelId="quiz_2"
									id="quiz_2"
									variant="filled"
									size="small"
								>
									{schoolLevelOptions.map((level) => (
										<MenuItem
											key={level.option_id}
											value={level.option_id.toString()}
										>
											{level.option_text}
										</MenuItem>
									))}
								</Select>
							)}
						/>
						{errors.quiz_2 && (
							<small className="text-danger">
								{errors.quiz_2.message}
							</small>
						)}
					</FormControl>
				</div>

				<div className={styles.form_education}>
					<CountryPicker
						onLocationChange={(locationData) => {
							updateStateLocation(locationData);
							setValue(
								"lat",
								locationData.latitude
									? locationData.latitude.toString()
									: "",
								{ shouldValidate: true, shouldTouch: true }
							);
							setValue(
								"lng",
								locationData.longitude
									? locationData.longitude.toString()
									: "",
								{
									shouldValidate: true,
									shouldTouch: true,
									shouldDirty: true,
								}
							);
							setValue(
								"state",
								locationData.longitude
									? locationData.userLocation?.state || ""
									: "",
								{
									shouldValidate: true,
									shouldTouch: true,
									shouldDirty: true,
								}
							);
							setValue(
								"lga",
								locationData.longitude
									? locationData.userLocation?.lga || ""
									: "",
								{
									shouldValidate: true,
									shouldTouch: true,
									shouldDirty: true,
								}
							);
							setValue(
								"town",
								locationData.longitude
									? locationData.userLocation?.town || ""
									: "",
								{
									shouldValidate: true,
									shouldTouch: true,
									shouldDirty: true,
								}
							);
						}}
					/>

					{(errors.lat ||
						errors.lng ||
						errors.state ||
						errors.lga ||
						errors.town) && (
						<small className="text-danger">
							{errors.lat?.message ||
								errors.lng?.message ||
								errors.state?.message ||
								errors.lga?.message ||
								errors.town?.message}
						</small>
					)}
				</div>

				<div className={styles.form_education}>
					<p>
						How many children under the age of 5 live in your
						household?
					</p>
					<FormControl fullWidth>
						<InputLabel id="quiz_3_label">Choose number</InputLabel>
						<Controller
							name="quiz_3"
							control={control}
							render={({ field }) => (
								<Select
									{...field}
									labelId="quiz_3_label"
									id="quiz_3"
									variant="filled"
									size="small"
								>
									{childrenUnderNineOptions.map((level) => (
										<MenuItem
											key={level.option_id}
											value={level.option_id.toString()}
										>
											{level.option_text}
										</MenuItem>
									))}
								</Select>
							)}
						/>
						{errors.quiz_3 && (
							<small className="text-danger">
								{errors.quiz_3.message}
							</small>
						)}
					</FormControl>
				</div>

				<div className={styles.form_education}>
					<p>How many rooms in your house are used for sleeping?</p>
					<FormControl fullWidth>
						<InputLabel id="quiz_4_label">Choose number</InputLabel>
						<Controller
							name="quiz_4"
							control={control}
							render={({ field }) => (
								<Select
									{...field}
									labelId="quiz_4_label"
									id="quiz_4"
									variant="filled"
									size="small"
								>
									{bedRoomOptions.map((level) => (
										<MenuItem
											key={level.option_id}
											value={level.option_id.toString()}
										>
											{level.option_text}
										</MenuItem>
									))}
								</Select>
							)}
						/>
						{errors.quiz_4 && (
							<small className="text-danger">
								{errors.quiz_4.message}
							</small>
						)}
					</FormControl>
				</div>

				<button type="submit">
					Submit <IoIosArrowRoundForward color="white" size={24} />
				</button>
			</form>
		</section>
	);
};

export default Demographics;
