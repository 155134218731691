const misSurveySection = {
    DEMOGRAPHICS: "Demographics",
    FEEDBACK: "Feedback",
    TREATMENT: "Treatment",
    PREVENTION: "Prevention",
    THANK_YOU: "Thank-you",
    MINI_REWARD: "Mini-reward",
}

export {misSurveySection}
