import React, { useEffect } from "react";
import styles from "./sectionstyle.module.css";
import Banner from "./banner/Banner";
import { IoIosArrowRoundForward } from "react-icons/io";
import { misSurveySection } from "../../../utils/constants";
import { useSurveySectionContent } from "../../../context/survey-track/SurveySectionContext";
import { useSurveyFormContext } from "../../../context/survey-form-data/SurverFormContext";
import { zodResolver } from "@hookform/resolvers/zod";
import { feedBackSchema } from "../../../validators/mis/demographics";
import { Controller, useForm } from "react-hook-form";
import RadioButtons from "../RadioInput";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { processSurveyData } from "../../../utils/helpers";
import { useSubmitSurveyData } from "../../../api/hooks";
import toast from "react-hot-toast";
import feedbackImage from "../../../images/headerimges/mis-survey/feedback-banner.svg";

const Feedback = () => {
	const { dispatch } = useSurveySectionContent();
	const { surveyFormData } = useSurveyFormContext();
	const { submitSurvey, isLoading } = useSubmitSurveyData({
		onSuccess: () => {
			dispatch({
				type: "UPDATE",
				payload: {
					currentSection: misSurveySection.THANK_YOU,
					showConfetti: false,
					formCompletedAt: "",
					rewardPrice: "",
				},
			});
		},
		onError: (error) => {
			const errorMessage = error?.response?.data?.message;
			if (Array.isArray(errorMessage) && errorMessage.length > 1) {
				toast.error("An error occured, please try again");
			} else {
				toast.error(
					errorMessage || "unable to submit survey, please try again"
				);
			}
		},
	});

	const {
		control,
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: zodResolver(feedBackSchema),
		defaultValues: surveyFormData?.surveyResponses,
	});

	const onSubmit = (data) => {
		const completeData = { ...surveyFormData?.surveyResponses, ...data };
		console.log({ completeData });

		processSurveyData(completeData, (responses) => {
			console.log({ responses });
			submitSurvey({
				survey_id: surveyFormData?.survey_id,
				channel: surveyFormData?.channel,
				agent: surveyFormData?.agent,
				survey_code: surveyFormData?.survey_code,
				respondent: surveyFormData?.respondent,
				response: responses,
			});
		});
	};

	const childrenReceivedSMCOptions = [
		{ option_text: "Yes", option_id: 122 },
		{
			option_text: "No and i have a child of 5 years or below",
			option_id: 123,
		},
		{
			option_text: "No, I do not have a child of 5 years or below",
			option_id: 124,
		},
		{
			option_text: "I don’t know what it is ",
			option_id: 125,
		},
	];

	const firstDoseOptions = [
		{ option_text: "Less than 12 months", option_id: 126 },
		{ option_text: "1 year; 2 years", option_id: 127 },
		{ option_text: "3 years", option_id: 128 },
		{ option_text: "4 years", option_id: 129 },
		{ option_text: "5 years", option_id: 130 },
		{ option_text: "above 5 years", option_id: 131 },
		{
			option_text: "I don't know about malaria vaccine ",
			option_id: 132,
		},
	];

	const childrenReceiveVaccineOptions = [
		{ option_text: "Yes", option_id: 133 },
		{
			option_text: "No and I have a child of 5 years or below",
			option_id: 134,
		},
		{
			option_text: " No, I do not have a child of 5 years or below",
			option_id: 135,
		},
		{
			option_text: "I don’t know what it is",
			option_id: 136,
		},
	];

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	}, []);
	return (
		<section className={styles.container}>
			<Banner
				title={"Feedback on Government Malaria Control Programs"}
				description={
					"We want to gauge your awareness and effectiveness of government’s interventions."
				}
				imageURL={feedbackImage}
			/>

			<form onSubmit={handleSubmit(onSubmit)}>
				<div className={styles.form_earning}>
					<p>You Earn</p>
					<h3>₦1,000 Naira</h3>
					<p>when you complete this survey</p>
				</div>

				<Controller
					name="quiz_18"
					control={control}
					render={({ field }) => (
						<RadioButtons
							field={field}
							question="Have you heard about Seasonal Malaria Chemoprevention (SMC)?"
							extraInfo="i.e chidren are given doses of antimalaria drugs periodically"
							items={[
								{ option_text: "Yes", option_id: 120 },
								{ option_text: "No", option_id: 121 },
							]}
							{...(register("quiz_18"), { required: true })}
							hasError={Boolean(errors.quiz_18)}
							errorMessage={errors?.quiz_18?.message || ""}
						/>
					)}
				/>

				<div className={styles.form_education}>
					<p>
						Have your children ever received Seasonal Malaria
						Chemoprevention (SMC) treatment?
					</p>
					<FormControl fullWidth>
						<InputLabel id="quiz_19_label">Choose one</InputLabel>
						<Controller
							name="quiz_19"
							control={control}
							render={({ field }) => (
								<Select
									{...field}
									labelId="quiz_19_label"
									id="quiz_19"
									variant="filled"
									size="small"
								>
									{childrenReceivedSMCOptions.map((level) => (
										<MenuItem
											key={level.option_id}
											value={level.option_id.toString()}
										>
											{level.option_text}
										</MenuItem>
									))}
								</Select>
							)}
						/>
					</FormControl>
					{errors.quiz_19 && (
						<small className="text-danger">
							{errors.quiz_19.message}
						</small>
					)}
				</div>

				<div className={styles.form_education}>
					<p>
						At what age should a child receive the first dose of the
						malaria vaccine?
					</p>
					<FormControl fullWidth>
						<InputLabel id="quiz_20_label">Choose one</InputLabel>
						<Controller
							name="quiz_20"
							control={control}
							render={({ field }) => (
								<Select
									{...field}
									labelId="quiz_20_label"
									id="quiz_20"
									variant="filled"
									size="small"
								>
									{firstDoseOptions.map((level) => (
										<MenuItem
											key={level.option_id}
											value={level.option_id.toString()}
										>
											{level.option_text}
										</MenuItem>
									))}
								</Select>
							)}
						/>
					</FormControl>
					{errors.quiz_20 && (
						<small className="text-danger">
							{errors.quiz_20.message}
						</small>
					)}
				</div>

				<div className={styles.form_education}>
					<p>
						Have your children ever received a malaria vaccine dose?
					</p>
					<FormControl fullWidth>
						<InputLabel id="quiz_21_label">Choose one</InputLabel>
						<Controller
							name="quiz_21"
							control={control}
							render={({ field }) => (
								<Select
									{...field}
									labelId="quiz_21_label"
									id="quiz_21"
									variant="filled"
									size="small"
								>
									{childrenReceiveVaccineOptions.map(
										(level) => (
											<MenuItem
												key={level.option_id}
												value={level.option_id.toString()}
											>
												{level.option_text}
											</MenuItem>
										)
									)}
								</Select>
							)}
						/>
					</FormControl>
					{errors.quiz_21 && (
						<small className="text-danger">
							{errors.quiz_21.message}
						</small>
					)}
				</div>

				<Controller
					name="quiz_22"
					control={control}
					render={({ field }) => (
						<RadioButtons
							field={field}
							question="Have you received free malaria treatment at a government facility in the last 6 months?"
							items={[
								{ option_text: "Yes", option_id: 137 },
								{
									option_text:
										"No and I went for in malaria treatment in a government health facility in the last 6 months",
									option_id: 138,
								},
								{
									option_text:
										" No, I did not go for malaria treatment in a government health facility in the last 6 months ",
									option_id: 139,
								},
							]}
							{...(register("quiz_22"), { required: true })}
							hasError={Boolean(errors.quiz_22)}
							errorMessage={errors?.quiz_22?.message || ""}
						/>
					)}
				/>

				<Controller
					name="quiz_23"
					control={control}
					render={({ field }) => (
						<RadioButtons
							field={field}
							question="Have you experienced any case where there was no malaria drugs available at a government health facilities in the last 6 months?"
							items={[
								{ option_text: "Yes", option_id: 140 },
								{
									option_text:
										"No and I went for in malaria treatment in a government health facility in the last 6 months",
									option_id: 141,
								},
								{
									option_text:
										" No, I did not go for malaria treatment in a government health facility in the last 6 months ",
									option_id: 142,
								},
							]}
							{...(register("quiz_23"), { required: true })}
							hasError={Boolean(errors.quiz_23)}
							errorMessage={errors?.quiz_23?.message || ""}
						/>
					)}
				/>

				<Controller
					name="quiz_24"
					control={control}
					render={({ field }) => (
						<RadioButtons
							field={field}
							question="How would you rate the government's efforts in combating malaria?"
							items={[
								{
									option_text: "Very effective",
									option_id: 143,
								},
								{
									option_text: "Somewhat effective",
									option_id: 144,
								},
								{ option_text: "Neutral", option_id: 145 },
								{
									option_text: "Somewhat ineffective",
									option_id: 146,
								},
								{
									option_text: "Very ineffective",
									option_id: 147,
								},
							]}
							{...(register("quiz_24"), { required: true })}
							hasError={Boolean(errors.quiz_24)}
							errorMessage={errors?.quiz_24?.message || ""}
						/>
					)}
				/>

				<button type="submit" disabled={isLoading}>
					{isLoading ? "Submitting" : "Submit"}{" "}
					<IoIosArrowRoundForward color="white" size={24} />
				</button>
			</form>
		</section>
	);
};

export default Feedback;
