import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import AppProviders from "./providers/AppProviders";
const client = new QueryClient()
const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
	<BrowserRouter>
		<AppProviders>
			<QueryClientProvider client={client}>
				<App />
			</QueryClientProvider>
		</AppProviders>
	</BrowserRouter>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
