import React, { useEffect } from "react";
import style from "./survey.module.css";
import WelcomePage from "../../components/mis-survey/sections/welcome/WelcomePage";
import Demographics from "../../components/mis-survey/sections/Demographics";
import Feedback from "../../components/mis-survey/sections/Feedback";
import Treatment from "../../components/mis-survey/sections/Treatment";
import { useSurveySectionContent } from "../../context/survey-track/SurveySectionContext";
import { misSurveySection } from "../../utils/constants";
import Prevention from "../../components/mis-survey/sections/Prevention";
import Thankyou from "../../components/mis-survey/sections/thank-you/Thankyou";
import { useLocation } from "react-router-dom";
import {
	STORAGE_KEY,
	useSurveyFormContext,
} from "../../context/survey-form-data/SurverFormContext";

const MISSurvey = () => {
	const { currentSurveySection, dispatch } = useSurveySectionContent();
	const { surveyFormData, dispatch: dispatchSurvey } = useSurveyFormContext();
	const location = useLocation();

	const endSurvey = () => {
		dispatchSurvey({ type: "CLEAR" });
		sessionStorage.removeItem("::agent::us-mis");
		sessionStorage.removeItem("::channel::us-mis");
		sessionStorage.removeItem(STORAGE_KEY);
	};

	useEffect(() => {
		dispatch({
			type: "UPDATE",
			payload: {
				currentSection: null,
				showConfetti: false,
				formCompletedAt: null,
			},
		});
		if (surveyFormData && surveyFormData?.channel !== "direct") {
			endSurvey();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	return (
		<section className={style.survey_container}>
			{!currentSurveySection?.currentSection && <WelcomePage />}

			{currentSurveySection?.currentSection ===
				misSurveySection.DEMOGRAPHICS && <Demographics />}

			{currentSurveySection?.currentSection ===
				misSurveySection.PREVENTION && <Prevention />}

			{currentSurveySection?.currentSection ===
				misSurveySection.TREATMENT && <Treatment />}

			{currentSurveySection?.currentSection ===
				misSurveySection.FEEDBACK && <Feedback />}

			{currentSurveySection?.currentSection ===
				misSurveySection.THANK_YOU && <Thankyou />}
		</section>
	);
};

export default MISSurvey;
