import { useState, useEffect } from "react";
import Geocode from "react-geocode";

const useGeolocation = () => {
	const googleKey = process.env.REACT_APP_GOOGLE_API;
	Geocode.setApiKey(googleKey);

	const [coordinates, setCoordinates] = useState(null);
	const [gLocation, setGLocation] = useState(null);
	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const getUserGeolocation = async () => {
			try {
				const permissionStatus = await navigator.permissions.query({
					name: "geolocation",
				});

				if (permissionStatus.state === "denied") {
					setError(true);
					setIsLoading(false);
					return;
				}

				if (
					permissionStatus.state === "prompt" ||
					permissionStatus.state === "granted"
				) {
					if (!navigator.geolocation) {
						setError(true);
						setIsLoading(false);
						return;
					}

					navigator.geolocation.getCurrentPosition(
						async (position) => {
							const { latitude, longitude } = position.coords;
							setCoordinates({ latitude, longitude });

							try {
								const response = await Geocode.fromLatLng(
									latitude,
									longitude
								);
								const addressComponents =
									response.results[0]?.address_components ||
									[];

								let town = "";
								let lga = "";
								let state = "";

								addressComponents.forEach(
									(component) => {
										component.types.forEach((type) => {
											switch (type) {
												case "neighborhood":
													town = component.long_name;
													break;
												case "administrative_area_level_2":
													lga = component.long_name;
													break;
												case "administrative_area_level_1":
													state = component.long_name;
													break;
												default:
													break;
											}
										});
									}
								);

								setGLocation({ town, lga, state });
								setIsLoading(false);
							} catch (geocodeError) {
								console.error(
									"Reverse Geocoding Error:",
									geocodeError
								);
								setError(true);
							} finally {
								setIsLoading(false);
							}
						},
						(geoError) => {
							console.error("Geolocation Error:", geoError);
							if (geoError.code === geoError.PERMISSION_DENIED) {
								setError(true);
							} else if (
								geoError.code === geoError.POSITION_UNAVAILABLE
							) {
								setError(true);
							} else if (geoError.code === geoError.TIMEOUT) {
								setError(true);
							} else {
								setError(true);
							}
							setIsLoading(false);
						}
					);
				}
			} catch (networkError) {
				console.error("Network Error:", networkError);
				setError(true);
				setIsLoading(false);
			}
		};

		getUserGeolocation();
	}, []);

	return { coordinates, gLocation, error, isLoading };
};

export default useGeolocation;
