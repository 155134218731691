import React, { useEffect } from "react";
import styles from "./sectionstyle.module.css";
import Banner from "./banner/Banner";
import { IoIosArrowRoundForward } from "react-icons/io";
import { useSurveySectionContent } from "../../../context/survey-track/SurveySectionContext";
import { misSurveySection } from "../../../utils/constants";
import { useSurveyFormContext } from "../../../context/survey-form-data/SurverFormContext";
import { zodResolver } from "@hookform/resolvers/zod";
import { treatmentSchema } from "../../../validators/mis/demographics";
import { Controller, useForm } from "react-hook-form";
import RadioButtons from "../RadioInput";
import {
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from "@mui/material";
import treatmentImage from "../../../images/headerimges/mis-survey/treatment-banner.svg";

const Treatment = () => {
	const { dispatch } = useSurveySectionContent();
	const { surveyFormData, dispatch: dispatchSurvey } = useSurveyFormContext();
	console.log("formData from prevention", surveyFormData);

	const {
		control,
		register,
		handleSubmit,
		watch,
		setValue,
		formState: { errors },
	} = useForm({
		resolver: zodResolver(treatmentSchema),
		defaultValues: surveyFormData?.surveyResponses,
	});
	const onSubmit = (data) => {
		console.log({ data });
		dispatchSurvey({
			type: "UPDATE_RESPONSES",
			payload: { ...surveyFormData?.surveyResponses, ...data },
		});
		dispatch({
			type: "UPDATE",
			payload: {
				currentSection: misSurveySection.FEEDBACK,
				showConfetti: false,
				formCompletedAt: misSurveySection.TREATMENT,
				rewardPrice: "180",
			},
		});
	};

	const formData = watch();

	const testCostOptions = [
		{ option_text: "Free", option_id: 75 },
		{ option_text: "N1 - N999", option_id: 76 },
		{ option_text: "N1,000 - N1,999", option_id: 77 },
		{ option_text: "N2000 - N2,999", option_id: 78 },
		{ option_text: "N3,000 - N3,999", option_id: 79 },
		{ option_text: "N4,000 - N4,999", option_id: 80 },
		{ option_text: "N5,000 - N5,999", option_id: 81 },
		{ option_text: "N6,000 - N6,999", option_id: 82 },
		{ option_text: "N7000 - N7,999;", option_id: 83 },
		{ option_text: "N8,000 - N8,999", option_id: 84 },
		{ option_text: "N9,000 - N9,999;", option_id: 85 },
		{ option_text: "N10,000 - N10,999", option_id: 86 },
		{
			option_text: "N11,000 - N11,999;",
			option_id: 87,
		},
		{ option_text: "N12,000 - N12,999", option_id: 88 },
		{ option_text: "N13,000 - N13,999", option_id: 89 },
		{ option_text: "N14,000 - N14,999", option_id: 90 },
		{ option_text: "Above N15,000", option_id: 91 },
		{ option_text: "I don't know", option_id: 92 },
	];

	const malariaDrugCostOptions = [
		{ option_text: "Free", option_id: 93 },
		{ option_text: "N1 - N999", option_id: 94 },
		{ option_text: "N1,000 - N1,999", option_id: 95 },
		{ option_text: "N2000 - N2,999", option_id: 96 },
		{ option_text: "N3,000 - N3,999", option_id: 97 },
		{ option_text: "N4,000 - N4,999", option_id: 98 },
		{ option_text: "N5,000 - N5,999", option_id: 99 },
		{ option_text: "N6,000 - N6,999", option_id: 100 },
		{ option_text: "N7000 - N7,999;", option_id: 101 },
		{ option_text: "N8,000 - N8,999", option_id: 102 },
		{ option_text: "N9,000 - N9,999;", option_id: 103 },
		{
			option_text: "N10,000 - N10,999",
			option_id: 104,
		},
		{
			option_text: "N11,000 - N11,999;",
			option_id: 105,
		},
		{
			option_text: "N12,000 - N12,999",
			option_id: 106,
		},
		{
			option_text: "N13,000 - N13,999",
			option_id: 107,
		},
		{
			option_text: "N14,000 - N14,999",
			option_id: 108,
		},
		{ option_text: "Above N15,000", option_id: 109 },
		{ option_text: "I don't know", option_id: 110 },
	];

	const lastDrugPurchaseTime = [
		{ option_text: "0 – 4 weeks ago,", option_id: 111 },
		{ option_text: "1 – 5 months ago", option_id: 112 },
		{
			option_text: "6 – 12 months ago,",
			option_id: 113,
		},
		{
			option_text: "Over one year ago",
			option_id: 114,
		},
		{ option_text: "I don’t know", option_id: 115 },
	];

	const hasOtherTestCost = formData?.quiz_14?.includes("91");
	const hasOtherMalariaCost = formData?.quiz_15?.includes("109");

	useEffect(() => {
		if (!hasOtherTestCost) {
			setValue("quiz_14_other_91", "");
		}
		if (!hasOtherMalariaCost) {
			setValue("quiz_15_other_109", "");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hasOtherTestCost, hasOtherMalariaCost]);

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	}, []);
	return (
		<section className={styles.container}>
			<Banner
				title={"Malaria Treatment & Affordability"}
				description={
					"We want to understand your access to and cost of malaria treatment."
				}
				imageURL={treatmentImage}
			/>

			<form onSubmit={handleSubmit(onSubmit)}>
				<div className={styles.form_earning}>
					<p>You Earn</p>
					<h3>₦1,000 Naira</h3>
					<p>when you complete this survey</p>
				</div>

				<Controller
					name="quiz_12"
					control={control}
					render={({ field }) => (
						<RadioButtons
							field={field}
							question="Have you or anyone in your household had fever in the last 2 weeks?"
							items={[
								{ option_text: "Yes", option_id: 69 },
								{ option_text: " No", option_id: 70 },
								{ option_text: "I don't know", option_id: 71 },
							]}
							{...(register("quiz_12"), { required: true })}
							hasError={Boolean(errors.quiz_12)}
							errorMessage={errors?.quiz_12?.message || ""}
						/>
					)}
				/>

				{formData.quiz_12 === "69" && (
					<Controller
						name="quiz_13"
						control={control}
						render={({ field }) => (
							<RadioButtons
								field={field}
								question="Was a blood sample taken from any part of their body for testing before treatment was given?"
								items={[
									{ option_text: "Yes", option_id: 72 },
									{ option_text: "No", option_id: 73 },
									{
										option_text: "I don't know",
										option_id: 74,
									},
								]}
								{...(register("quiz_13"), { required: true })}
								hasError={Boolean(errors.quiz_13)}
								errorMessage={errors?.quiz_13?.message || ""}
							/>
						)}
					/>
				)}

				{formData.quiz_13 === "72" && (
					<div className={styles.form_education}>
						<p>How much did the test cost you?</p>
						<FormControl fullWidth>
							<InputLabel id="quiz_14_label">
								Choose one
							</InputLabel>
							<Controller
								name="quiz_14"
								control={control}
								render={({ field }) => (
									<Select
										{...field}
										labelId="quiz_14_label"
										id="quiz_14"
										variant="filled"
										size="small"
									>
										{testCostOptions.map((level) => (
											<MenuItem
												key={level.option_id}
												value={level.option_id.toString()}
											>
												{level.option_text}
											</MenuItem>
										))}
									</Select>
								)}
							/>
						</FormControl>
						{errors.quiz_14 && (
							<small className="text-danger">
								{errors.quiz_14.message}
							</small>
						)}

						{formData?.quiz_14?.includes("91") && (
							<div className={styles.other_text_input_wrapper}>
								<TextField
									id="quiz_14_other_91"
									label="enter other prices here"
									{...register("quiz_14_other_91", {
										required: true,
									})}
									variant="outlined"
									size="small"
									className={styles.other_text_input}
								/>
								{errors.quiz_14_other_91 && (
									<small className="text-danger">
										{errors.quiz_14_other_91.message}
									</small>
								)}
							</div>
						)}
					</div>
				)}

				<div className={styles.form_education}>
					<p>How much did your last malaria drugs cost?</p>
					<FormControl fullWidth>
						<InputLabel id="quiz_15_label">Choose one</InputLabel>
						<Controller
							name="quiz_15"
							control={control}
							render={({ field }) => (
								<Select
									{...field}
									labelId="quiz_15_label"
									id="quiz_15"
									variant="filled"
									size="small"
								>
									{malariaDrugCostOptions.map((level) => (
										<MenuItem
											key={level.option_id}
											value={level.option_id.toString()}
										>
											{level.option_text}
										</MenuItem>
									))}
								</Select>
							)}
						/>
					</FormControl>
					{errors.quiz_15 && (
						<small className="text-danger">
							{errors.quiz_15.message}
						</small>
					)}

					{formData?.quiz_15?.includes("109") && (
						<div className={styles.other_text_input_wrapper}>
							<TextField
								id="quiz_15_other_109"
								label="enter other prices here"
								{...register("quiz_15_other_109", {
									required: true,
								})}
								variant="outlined"
								size="small"
								className={styles.other_text_input}
							/>
							{errors.quiz_15_other_109 && (
								<small className="text-danger">
									{errors.quiz_15_other_109.message}
								</small>
							)}
						</div>
					)}
				</div>

				<div className={styles.form_education}>
					<p>When did you buy your last malaria drug?</p>
					<FormControl fullWidth>
						<InputLabel id="quiz_16_label">Choose one</InputLabel>
						<Controller
							name="quiz_16"
							control={control}
							render={({ field }) => (
								<Select
									{...field}
									labelId="quiz_16_label"
									id="quiz_16"
									variant="filled"
									size="small"
								>
									{lastDrugPurchaseTime.map((level) => (
										<MenuItem
											key={level.option_id}
											value={level.option_id.toString()}
										>
											{level.option_text}
										</MenuItem>
									))}
								</Select>
							)}
						/>
						{errors.quiz_16 && (
							<small className="text-danger">
								{errors.quiz_16.message}
							</small>
						)}
					</FormControl>
				</div>

				<Controller
					name="quiz_17"
					control={control}
					render={({ field }) => (
						<RadioButtons
							field={field}
							question="Rate the affordability of malaria drugs in your community’ "
							items={[
								{
									option_text: "Very affordable",
									option_id: 116,
								},
								{
									option_text: "Somewhat affordable",
									option_id: 117,
								},
								{
									option_text:
										"Neutral - Neither affordable nor expensive Somewhat expensive",
									option_id: 118,
								},
								{
									option_text: "Very expensive",
									option_id: 119,
								},
							]}
							{...(register("quiz_17"), { required: true })}
							hasError={Boolean(errors.quiz_17)}
							errorMessage={errors?.quiz_17?.message || ""}
						/>
					)}
				/>

				<button type="submit">
					Submit <IoIosArrowRoundForward color="white" size={24} />
				</button>
			</form>
		</section>
	);
};

export default Treatment;
