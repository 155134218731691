
// export type FormBannerAction =
// 	| { type: "UPDATE"; payload: FormBannerContent }
// 	| { type: "CLEAR" };

const SurveySectionReducer = (state, action) => {
	switch (action.type) {
		case "UPDATE": {
			return {
				currentSurveySection: action.payload,
			};
		}
		case "CLEAR": {
			return {
				currentSurveySection: null,
			};
		}

		default:
			return state;
	}
};

export default SurveySectionReducer;
