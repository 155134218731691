import { z } from "zod";

const demographicsSchema = z.object({
	age: z.number().min(0, "date of birth is required"),
	lat: z.string().nonempty("location is required"),
	lng: z.string().nonempty("location is required"),
	lga: z.string().nonempty("LGA is required"),
	town: z.string().nonempty("state, LGA and town is required"),
	state: z.string().nonempty("state is required"),
	quiz_1: z.string().nonempty("gender is required"),
	quiz_2: z.string().nonempty("education is required"),
	quiz_3: z.string().nonempty("this information is required"),
	quiz_4: z.string().nonempty("this information is required"),
});

const preventionSchema = z
	.object({
		quiz_5: z.string().nonempty("this information is required"),
		quiz_6: z.string().optional(),
		quiz_7: z.string().nonempty("this information is required"),
		quiz_8: z.array(z.string()).min(1, "select at least one"),
		quiz_8_other_39: z.string().optional(),
		quiz_9: z.string().nonempty("this information is required"),
		quiz_10: z.string().nonempty("this information is required"),
		quiz_11: z.string().nonempty("this information is required"),
		quiz_11_other_67: z.string().optional(),
	})
	.superRefine((value, ctx) => {
		if (value.quiz_5 === "26" && value.quiz_6 === "") {
			ctx.addIssue({
				path: ["quiz_6"],
				message: "this information is required",
				code: z.ZodIssueCode.custom,
			});
			return false;
		}
		if (value?.quiz_8?.includes("39") && value?.quiz_8_other_39 === "") {
			ctx.addIssue({
				path: ["quiz_8_other_39"],
				message: "specify others above",
				code: z.ZodIssueCode.custom,
			});
			return false;
		}

		if (value?.quiz_11?.includes("69") && value?.quiz_11_other_67 === "") {
			ctx.addIssue({
				path: ["quiz_11_other_67"],
				message: "specify others above",
				code: z.ZodIssueCode.custom,
			});
			return false;
		}
		return true;
	});

const treatmentSchema = z
	.object({
		quiz_12: z.string().nonempty("this information is required"),
		quiz_13: z.string().optional(),
		quiz_14: z.string().optional(),
		quiz_14_other_91: z.string().optional(),
		quiz_15: z.string().nonempty("this information is required"),
		quiz_15_other_109: z.string().optional(),
		quiz_16: z.string().nonempty("this information is required"),
		quiz_17: z.string().nonempty("this information is required"),
	})
	.superRefine((value, ctx) => {
		if (value.quiz_12 === "69" && value.quiz_13 === "") {
			ctx.addIssue({
				path: ["quiz_13"],
				message: "this information is required",
				code: z.ZodIssueCode.custom,
			});
			return false;
		}
		if (value.quiz_13 === "72" && value.quiz_14 === "") {
			ctx.addIssue({
				path: ["quiz_14"],
				message: "this information is required",
				code: z.ZodIssueCode.custom,
			});
			return false;
		}
		if (value?.quiz_14?.includes("91") && value?.quiz_14_other_91 === "") {
			ctx.addIssue({
				path: ["quiz_14_other_91"],
				message: "specify others above",
				code: z.ZodIssueCode.custom,
			});
			return false;
		}

		if (value?.quiz_15?.includes("109") && value?.quiz_15_other_109 === "") {
			ctx.addIssue({
				path: ["quiz_15_other_109"],
				message: "specify others above",
				code: z.ZodIssueCode.custom,
			});
			return false;
		}
		return true;
	});

const feedBackSchema = z.object({
	quiz_18: z.string().nonempty("this information is required"),
	quiz_19: z.string().nonempty("this information is required"),
	quiz_20: z.string().nonempty("this information is required"),
	quiz_21: z.string().nonempty("this information is required"),
	quiz_22: z.string().nonempty("this information is required"),
	quiz_23: z.string().nonempty("this information is required"),
	quiz_24: z.string().nonempty("this information is required"),
});

export {
	demographicsSchema,
	preventionSchema,
	treatmentSchema,
	feedBackSchema,
};
